div[tag=subtract] {
    opacity: 0;
    transition: 0.35s;
}

div[tag=boxes]:hover div[tag=subtract] {
    /* visibility: visible; */
    opacity: 1
}

